@import '../../colors';

.fact{
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .fact-icon{
    color: $heroic-red;
    font-size: 36px;
    margin-right: 10px;

    svg{
      display: block;
    }
  }

  .fact-text{
    h4{
      font-size: 14px;
      margin: 0;
      color: $intelligent-black;
    }

    p{
      font-size: 12px;
      color: #606060;
      margin: 0;
    }
  }
}
