@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');
@import url('react-notifications/lib/notifications.css');
@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;700;800;900;1000&display=swap');
@import 'colors';

.notification-container {
  width: unset;
  overflow: visible;
}

.notification-container .notification{
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.notification-container h4 {
  color: white !important;
  font-size: 16px !important;
}

.notification-container .message {
  font-size: 14px !important;
}

body{
  background: $off-white;
  font-family: "Recursive", sans-serif !important;
}

input, .MuiDialogTitle-root {
  font-family: "Recursive", sans-serif !important;
}

.btn{
  min-width: unset !important;
  padding: 10px 15px !important;
  font-family: "Recursive", sans-serif !important;
  font-weight: 800 !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  border-radius: 0 !important;
  line-height: 16px !important;

  &.btn-yellow{
    background: $charged-yellow !important;
    color: $intelligent-black !important;
  }

  &.btn-red{
    background: $heroic-red !important;
    color: white !important;
  }

  &.btn-black{
    background: $intelligent-black !important;
    color: white !important;
  }

  &.btn-icon{
    font-size: 20px;
    padding: 0 !important;
    height: 40px;
    width: 40px;
    text-align: center;
  }

  &.btn-small{
    font-size: 13px !important;
    line-height: 13px !important;

    svg{
      font-size: 14px !important;
    }
  }

  &:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.btn-block{
    width: 100%;
  }
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeader {
  font-family: "Frutiger", sans-serif !important;
}

.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.MuiDataGrid-root {
  border: 0 !important;
}

hr{
  border-color: rgba(0,0,0,0.1);
}

select{
  font-family: "Recursive", sans-serif;
  padding: 12px;
  border: 0;
  -webkit-appearance: none;
  background-color: rgba(0,0,0,0.1);
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  padding-right: 30px;
}

.box{
  border-bottom: 3px solid $charged-yellow;
  background: #FFF;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.10);
  padding: 40px;

  h4:first-child{
    margin-top: 0;
  }
}

.styled-progress.MuiLinearProgress-root{
  background: rgba(0,0,0,0.1);
  height: 10px;

  .MuiLinearProgress-bar{
    background: $heroic-red;
  }
}

*:focus{
  outline: none !important;
}

.modal-content{
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.10);
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  min-width: 400px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: scroll;

  h4{
    margin: 0;
  }
}

input {
  padding: 12px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: none;
  width: 100%;
}

.form-label{
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
  margin-top: 15px;
}

.MuiFormControlLabel-label{
  font-family: "Recursive", sans-serif !important;
  font-size: 14px !important;
}
