.styled-table-container {
  font-family: "Recursive", sans-serif !important;
  background: transparent !important;
}

.styled-table {
  font-family: "Recursive", sans-serif !important;
  border: 0 !important;

  .MuiChip-root {
    font-family: "Recursive", sans-serif !important;
  }

  th {
    font-weight: 800 !important;
  }

  th, td {
    font-family: "Recursive", sans-serif;
  }

  tr {
    &:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  th, td {
    border: none !important;
  }
}

.fancy-heading {
  font-family: "Recursive", sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
}

.select-chip {
  .MuiSelect-select {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .MuiChip-root {
    margin: 2px;
  }
}
