@import "../../colors";


.box.player {
  margin-top: 30px;
  .player-slider {
    margin-top: 10px;
    margin-bottom: 10px;

    .MuiSlider-rail {
      background: rgba(0, 0, 0, 0.3);
    }

    .MuiSlider-track {
      background: $heroic-red;
      border: 0;
    }

    .MuiSlider-thumb {
      background: $heroic-red;
      width: 15px;
      height: 15px;

      &:hover{
        box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.1);
      }

      &.Mui-active{
        box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.box.actions{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.transcript-wrapper {
  height: 100%;
  overflow-y: scroll;
  position: relative;

  @media(max-width: 900px){
    overflow-y: visible;
  }

  .transcript{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
  }
}

.btn-small {
  margin-right: 30px;
}


.btn.center {
  margin: 0 auto;
}

.btn.right {
  margin-left: auto;
  margin-right: 0;
}

.btn.left {
  margin-right: auto;
}
