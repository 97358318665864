@import '../../colors';

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.right {
  text-align: right;
  float: right;
}

.transcript-segment {
  padding: 20px;

  &.border{
    border-top: 1px solid rgba(0,0,0,0.1);
  }

  &.bot {
    background: rgba(0, 0, 0, 0.05);
  }

  .btn-small {
  margin-right: 10px;
  }

  .segment-header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4{
      margin: 0;

      span{
        font-weight: 400;
        color: #606060;
      }
    }

    h5{
      margin: 0;
      color: #606060;
      font-style: italic;
      font-weight: 400;
    }
  }

  p {
    margin: 20px 0;
    color: #606060;
  }

  .playing{
    margin-left: 10px;
    margin-right: 10px;
    color: #606060;
    font-size: 14px;
    padding-left: 17px;
    position: relative;

    &:before{
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 40px;
      background: $heroic-red;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      animation: pulse 1s infinite;
    }
  }

  .image-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; /* Adjust this margin as needed */
  }
}
