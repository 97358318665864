.styled-table {
  font-family: "Recursive", sans-serif !important;
  border: 0 !important;
  background: transparent !important;

  .MuiChip-root {
    font-family: "Recursive", sans-serif !important;
  }

  th{
    font-weight: 800 !important;
  }

  th, td{
    font-family: "Recursive", sans-serif;
  }

  .MuiToolbar-root{
    p {
      font-family: "Recursive", sans-serif !important;
    }
  }

  tr {
    &:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  th, td {
    border: none !important;
  }
}

.dialog-content {
  padding: 20px;
}

.btn{
  margin-right: 20px;
}
