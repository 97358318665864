.flow-builder-content{
  background: rgba(0, 0, 0, 0.05) !important;
  padding: 20px;
}

.flow-node {
  border: 2px dashed rgba(0, 0, 0, 0.10);
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 14px;
  min-width: 250px;
  color: #606060;

  span {
    display: block;
    font-weight: bold;
    color: #1a1a1a;

    &:not(:first-child){
      margin-top: 5px;
    }
  }

  &.welcome-message span {
    color: #4CAF50;
  }

  &.end-message span{
    color: #ff0000;
  }

  .btn-green{
    color: #38d010;
  }
}

.edit-node-modal{
  h4{
    margin: 0;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  input {
    display: block;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    border: none;
  }

  select {
    display: block;
    width: 100%;
  }
}

.add-node {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none
}

.flow-node-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .btn-yellow {
    background-color: #fdd835;
    color: #000;
    &:hover {
      background-color: darken(#fdd835, 10%);
    }
  }

  .btn-delete {
    background-color: red;
    color: #000000;
    &:hover {
      background-color: #cc0000;
    }
  }
}

.swal2-container {
  z-index: 1500 !important;
}

.option-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.btn-add-option {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
}

.btn-remove-option {
  background-color: red;
  color: white;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darkred;
  }
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #8293ee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
