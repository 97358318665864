@import '../../colors';

.editable-fact{
  .edit-button {
    min-width: unset;
    padding: 0;
    background: transparent !important;
    font-size: 16px;
    color: $heroic-red;
    margin-left: 5px;
    vertical-align: text-top;
  }
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #8293ee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
