@import '../../colors';

.fancy-heading{
  display: flex;
  align-items: center;

  h1{
    color: white;
    font-size: 26px;
    background: $heroic-red;
    padding: 4px 10px;
    margin-left: 5px;
  }

  margin-bottom: 30px;
}
