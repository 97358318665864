.styled-table {
  font-family: "Recursive", sans-serif !important;
  border: 0 !important;

  .MuiChip-root {
    font-family: "Recursive", sans-serif !important;
  }

  th{
    font-weight: 800 !important;
  }

  th, td{
    font-family: "Recursive", sans-serif;
  }

  .MuiToolbar-root{

    p {
      font-family: "Recursive", sans-serif !important;
    }
  }

  .actions-cell {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 8px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
