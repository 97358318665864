.flow-selector {
    min-width: 200px;
}

.voice-selector{
  display: flex;
  margin-bottom: 20px;

  button {
    flex-shrink: 0;
  }

  input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 12px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    border: none;
  }
}

.dialog-content {
    padding: 20px;
  }

.delete-flow-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
