@import '../../colors';

.header{
  background: $off-white;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.10);

  .header-inner{
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo{
      img{
        max-height: 40px;
      }
    }

    .header-nav{
      display: flex;
      align-items: center;

      nav{
        display: flex;
        align-items: center;

        a{
          color: $intelligent-black;
          font-weight: bold;
          text-decoration: none;
          margin-right: 30px;
          font-size: 15px;

          &.active{
            color: $heroic-red;
          }
        }
      }

      .header-user{
        display: flex;
        align-items: center;

        svg.user-icon{
          margin-right: 10px;
          font-size: 34px;
          display: block;
          color: $heroic-red;
        }

        .header-user-info{
          color: #999;
          font-size: 12px;
          font-style: italic;

          span{
            color: $intelligent-black;
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            display: block;
          }
        }
      }
    }
  }
}
