@import '../../colors';

.login-form {
  max-width: 300px;
  margin: 0 auto;

  input {
    display: block;
    border: 0;
    padding: 15px 20px;
    width: -webkit-fill-available;
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.05);
  }

  .inputError, .formError {
    color: $heroic-red;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 10px;
    display: block;
  }
}
