.styled-table {
  font-family: "Recursive", sans-serif !important;
  border: 0 !important;

  .MuiChip-root {
    font-family: "Recursive", sans-serif !important;
  }

  th{
    font-weight: 800 !important;
  }

  th, td{
    font-family: "Recursive", sans-serif;
  }

  .MuiToolbar-root{

    p {
      font-family: "Recursive", sans-serif !important;
    }
  }
}

.filters-drawer {
  margin-bottom: 30px;

  label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  select {
    width: 100%;
    box-sizing: border-box;
  }

  .MuiStack-root{
    height: unset;
    overflow: visible;
    padding-top: 0;
    flex-grow: 1;
  }

  .btn {
    height: 42px;
    width: 42px;
    flex-shrink: 0;
    margin-left: 10px;
    padding: 0 !important;
  }

  .MuiInputBase-root {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;

    .MuiInputBase-input {
      padding: 12px;
      font-size: 13px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 0 !important;
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-bottom-width: 2px !important;
      }
    }

    svg {
      font-size: 18px;
    }
  }
}
