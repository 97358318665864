@import '../../colors';

.fancy-heading-2{
  display: flex;
  align-items: center;

  h1{
    font-size: 26px;
    color: $intelligent-black;

    span{
      background: $heroic-red;
      padding: 4px 10px;
      margin-right: 5px;
      color: white;
    }
  }

  margin-bottom: 30px;
}
